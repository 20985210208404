<template>
  <div id="app" class="min-h-screen w-screen bg-gray-50 dark:bg-gray-900">
    <VitePwaManifest />
    <DarkModeListener />
    <slot />
  </div>
</template>

<style>
html {
  box-sizing: border-box;
  color: #2c3e50;
}
</style>
<script setup lang="ts">
</script>
